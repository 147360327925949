<template>
    <b-container>
        <b-modal id="BigImage" title="BigImage" hide-footer hide-header size="lg">
            <b-img fluid :src="bigImage" class="w-100"></b-img>
        </b-modal>
        <b-row>
            <b-col class="body">
                <b-row class="news_body mb-5">
                    <b-col class="p-0 mt-5">
                        <b-row v-if="!this.idNews">
                            <b-col class="ml-5 mb-3 mr-5">
                                <b-input-group size="lg">
                                <b-input-group-prepend>
                                    <b-icon icon="search" variant="dark" class="icon_search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input placeholder="найти новость" v-model="word"
                                              class="p-3 pl-5"></b-form-input>
                            </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="!this.idNews">
                            <b-col class="ml-5 mb-3">
                                <b-pagination
                                        class=" float-end"
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                ></b-pagination>
                            </b-col>
                        </b-row>
                        <b-row class="m-0 mb-5 p-0" v-for="(item, i) in newsPerPage" :key="'news'+i">
                            <b-col cols="12" md="6" class="p-0">
                                <div class="date mb-3">
                                    {{date(item.date)}}
                                </div>
                                <div class="title ml-5 mt-3">
                                    <router-link :to="{name: 'news', params: { id: item.id}}" style="color: #000">{{item.title}}</router-link>
                                </div>
                                <div class="text ml-5 mt-2" v-html="item.content">

                                </div>
                            </b-col>
                            <b-col cols="12" md="6" class="text-center mt-5 pr-5">
                                <b-row class="mt-3 pr-3">
                                    <b-col>
                                        <b-img-lazy :src="img_path+item.poster" class="image m-3"></b-img-lazy>
                                    </b-col>
                                </b-row>
                                <b-row class="image-gallery m-auto">
                                    <b-col cols="6" v-for="(image, j) in item.images" :key="'image'+j">
                                        <a @click="showImage(img_path+image.image)">
                                            <b-img-lazy thumbnail :src="img_path+image.image" class="image_thumb m-0 mb-3" ></b-img-lazy>
                                        </a>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="!this.idNews">
                            <b-col class="ml-5 mb-3">
                                <b-pagination
                                        class=" float-end"
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                ></b-pagination>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "List",
        data() {
            return {
                word: '',
                perPage: 5,
                currentPage: 1,
                bigImage: null,
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            news: function(){
                return this.$store.getters.allNews;
            },
            rows() {
                return this.filteredNews.length
            },
            filteredNews() {
                if (this.idNews){
                    return this.news.filter(item => {
                        return (item.id == this.idNews)
                    })
                }
                else
                    return this.news.filter(item => {
                        return item.title.toLowerCase().includes(this.word.toLowerCase())
                    })
            },
            newsPerPage(){
                return this.filteredNews.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage,
                );
            },
            idNews: function(){
                return this.$route.params.id;
            }
        },
        methods: {
            date: function (date) {
                return moment(date).locale('ru').format('DD MMMM');
            },
            showImage: function (image) {
                this.bigImage = image;
                this.$bvModal.show('BigImage');
            }
        },
        mounted() {
            this.$store.dispatch('getDepartBySlug', );
        }
    }
</script>

<style scoped>

    .body {
        background-color: var(--background-color-primary);
        width: 100%;
        border: 1px;
    }

    .news_body {
        position: relative;
        max-width: 1500px;
        margin-top: -50px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--background-color-secondary);
        width: 100%;
        min-height: 300px;
        border-radius: 32px;
    }

    .date {
        width: 260px;
        background-color: #FECC00;
        padding: 16px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 32px;
        color: #333;
    }

    .title {
        font-family: SF MEDIUM, sans-serif  ;
        font-style: normal;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: var(--text-secondary-color);
    }

    .text {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 27px;
        color: var(--text-secondary-color);
    }

    .image, .image_thumb{
        width:100%;
        max-width: 600px;
    }

    .image_thumb {
        cursor: pointer;
    }

    .image-gallery {
        width: 100%;
        max-width: 620px;
    }

    .icon_search {
        position: absolute;
        z-index: 10;
        font-size: 18px;
        top: 15px;
        left: 12px;
    }

    ::v-deep .page-item.disabled .page-link, ::v-deep .form-control {
        background-color: var(--background-input);
        border-color: var(--background-input-border);
    }

    ::v-deep .page-item.active .page-link {
        background-color: #F2913A !important;
        border-color: #F2913A;
    }

</style>